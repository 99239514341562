import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { AppContext } from '_context'
import { RaimanaPortfolio } from '_components/templates/'
import variables from '_config/css-variables'

const RaimanaCaseStudy = ({ data }) => {
  const content = data.allContentfulCaseStudyType.nodes[0]
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const results = JSON.parse(content.results.internal.content)

  useEffect(() => {
    setNavbarLight(true)
  }, [setNavbarColor, setNavbarLight])

  return (
    <RaimanaPortfolio
      navbarColorOverride={variables['raimana-blue-dark']}
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageBackgroundImage={content.pageThumbnail.file.url}
      aboutDescription={content.description1.internal.content}
      aboutImage={content.imageGroup1[0].fluid}
      opportunityImage={content.imageGroup2[0].fluid}
      opportunityDescription={content.description2.internal.content}
      uberTitle={content.title3}
      uberDescription={content.description3.internal.content}
      uberImage={content.imageGroup3[0].fluid}
      productTitle={content.title4}
      productDescription={content.description4.internal.content}
      productImage={content.imageGroup4[0].fluid}
      viewDescription={content.description5.internal.content}
      viewImage={content.imageGroup5[0].fluid}
      marketplaceTitle={content.title6}
      marketplaceDescription={content.description6.internal.content}
      marketplaceImage={content.imageGroup6[0].fluid}
      techTitle={content.title7}
      techDescription={content.description7.internal.content}
      techImage={content.imageGroup7[0].fluid}
      proudCases={content.proudCases}
      finalPhaseTitle={content.title8}
      finalPhaseDescription={content.description8.internal.content}
      finalPhaseImage={content.imageGroup8[0].fluid}
      titleResults={results.Title}
      firstDescription={results.Description1}
      secondDescription={results.Description2}
      thirdDescription={results.Description3}
    />
  )
}

export const query = graphql`
  query contentfulRaimanaCaseStudy($locale: String) {
    allContentfulCaseStudyType(
      filter: { pageTitle: { regex: "/Raimana/" }, fields: { localeKey: { eq: $locale } } }
    ) {
      nodes {
        pageTitle
        pageDescription
        pageThumbnail {
          description
          file {
            url
          }
        }
        description1 {
          internal {
            content
          }
        }
        imageGroup1 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description2 {
          internal {
            content
          }
        }
        imageGroup2 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title3
        description3 {
          internal {
            content
          }
        }
        imageGroup3 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title4
        description4 {
          internal {
            content
          }
        }
        imageGroup4 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description5 {
          internal {
            content
          }
        }
        imageGroup5 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title6
        description6 {
          internal {
            content
          }
        }
        imageGroup6 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title7
        description7 {
          internal {
            content
          }
        }
        imageGroup7 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title8
        description8 {
          internal {
            content
          }
        }
        imageGroup8 {
          description
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        results {
          internal {
            content
          }
        }
        proudCases {
          id
          title
          main
          platforms {
            internal {
              content
            }
          }
          projectColor
          projectName
          thumbnail {
            description
            fixed {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`

RaimanaCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default RaimanaCaseStudy
